import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "2rem",
            padding: "2rem",
          }}
        >
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${i}`}
                  style={{
                    backgroundColor: "#f8f9fa",
                    padding: "2rem",
                    borderRadius: "10px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  }}
                >
                  <p
                    style={{
                      fontSize: "1.8rem",
                      fontWeight: "bold",
                      margin: 0,
                      lineHeight: "1.4",
                    }}
                  >
                    "{d.text}"
                  </p>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
