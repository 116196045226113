import React from "react";
import "./gallery.css";

export const Gallery = ({ data }) => {
  return (
    <section className="gallery-section" id="gallery">
      <div className="gallery-title">
        <h2>Featured Awarded Sites</h2>
      </div>
      <div className="gallery-grid">
        {data?.map((item, index) => (
          <div key={index} className="gallery-item">
            <img
              src={item.largeImage}
              alt={item.title}
              className="gallery-image"
            />
            <div className="gallery-overlay">
              <h3 className="gallery-title-overlay">{item.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
