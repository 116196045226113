import React, { useState, useEffect } from "react";
import "./header.css"; // Add this at the top of your file if not already there
import { FaPen, FaCheckCircle } from "react-icons/fa"; // Optional: for icons

export const Header = (props) => {
  // Define backgrounds array before the state
  const backgrounds = [
    "hindu.jpg",
    "buddhist.jpg",
    "synagogue.jpg",
    "masjid.jpeg",
  ];

  const [count, setCount] = useState(0);
  const [currentBg, setCurrentBg] = useState(0);

  // Background rotation effect
  useEffect(() => {
    const rotateInterval = setInterval(() => {
      setCurrentBg((prev) => (prev + 1) % backgrounds.length);
    }, 5000);

    // Cleanup interval on unmount
    return () => clearInterval(rotateInterval);
  }, []);

  const backgroundStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${process.env.PUBLIC_URL}/img/${backgrounds[currentBg]})`,
    transition: "all 3s ease-in-out",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  // Counter effect
  useEffect(() => {
    const target = 52580000;
    const duration = 5000; // 5 seconds
    const steps = 60;
    const increment = target / steps;
    let current = 0;

    const timer = setInterval(() => {
      current += increment;
      if (current >= target) {
        setCount(target);
        clearInterval(timer);
      } else {
        setCount(Math.floor(current));
      }
    }, duration / steps);

    return () => clearInterval(timer);
  }, []);

  return (
    <header id="header">
      <div className="intro" style={backgroundStyle}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 intro-text">
              <h1
                style={{
                  fontSize: "6rem",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                {props.data ? props.data.title : "Loading"}
                <span></span>
              </h1>
              <p
                style={{
                  fontSize: "2.5rem",
                  lineHeight: "1.8",
                  marginBottom: "40px",
                  padding: "0 20px",
                }}
              >
                {props.data ? props.data.paragraph : "Loading"}
              </p>
            </div>
          </div>
        </div>
        <div className="stats-cards">
          <div className="stat-card">
            <div className="counter card-icon">${count.toLocaleString()}</div>
            <h3>Over 8 Figures Awarded</h3>
            <p>
              Winning a grant can be a transformative experience that can help
              individuals and organizations achieve their goals and make a
              positive impact in their communities.
            </p>
          </div>

          <div className="stat-card">
            <FaPen className="card-icon" />
            <h3>Unique and Holistic Writing</h3>
            <p>
              Leveraging our deep expertise, proven methods and existing
              partnerships, we do everything possible to take you past the
              finish line to win, to protect you!
            </p>
          </div>

          <div className="stat-card">
            <FaCheckCircle className="card-icon" />
            <h3>Verified with Sam.gov</h3>
            <p>
              SAM.gov registration process can be complex and time-consuming,
              requiring applicants to provide a significant amount of
              information. We assist you during registration throughout your
              process.
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};
