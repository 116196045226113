import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [showTerms, setShowTerms] = useState(false);

  const openTermsPopup = () => {
    const isMobile = window.innerWidth < 768;
    if (isMobile) {
      // On mobile, show popup with adjusted styles
      setShowTerms(true);
    } else {
      // On desktop, show popup
      setShowTerms(true);
    }
  };

  return (
    <div>
      <div id="contact" style={{ background: "#fff" }}>
        <div className="container">
          <div
            className="col-md-6 col-md-offset-3"
            style={{ margin: "0 auto" }}
          >
            <div
              style={{
                background: "#fff",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                borderRadius: "10px",
                padding: "25px",
                marginBottom: "20px",
              }}
            >
              <h3
                style={{
                  color: "#1e2022",
                  marginBottom: "20px",
                  fontSize: "24px",
                  fontWeight: "600",
                  textAlign: "left",
                }}
              >
                Contact Info
              </h3>

              <div className="contact-item" style={{ marginBottom: "15px" }}>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#1e2022",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "500",
                      color: "#000",
                    }}
                  >
                    <i
                      className="fa fa-map-marker"
                      style={{ marginRight: "8px", color: "#000" }}
                    ></i>
                    Address
                  </span>
                  <div style={{ paddingLeft: "25px", color: "#000" }}>
                    3169 Shofner Pl, San Jose CA 95111
                  </div>
                </p>
              </div>

              <div className="contact-item" style={{ marginBottom: "15px" }}>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#1e2022",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "500",
                      color: "#000",
                    }}
                  >
                    <i
                      className="fa fa-phone"
                      style={{ marginRight: "8px", color: "#000" }}
                    ></i>
                    Phone
                  </span>
                  <div style={{ paddingLeft: "25px", color: "#000" }}>
                    (510) 648-8460
                  </div>
                </p>
              </div>

              <div className="contact-item" style={{ marginBottom: "15px" }}>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#1e2022",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "500",
                      color: "#000",
                    }}
                  >
                    <i
                      className="fa fa-envelope-o"
                      style={{ marginRight: "8px", color: "#000" }}
                    ></i>
                    Email
                  </span>
                  <div style={{ paddingLeft: "25px", color: "#000" }}>
                    info@westcliff.io
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a
                      href={props.data ? props.data.facebook : "/"}
                      style={{
                        color: "#000",
                        display: "block",
                        fontSize: "24px",
                        margin: "0 10px",
                      }}
                    >
                      <i
                        className="fa fa-facebook"
                        style={{
                          color: "#000",
                          width: "24px",
                          height: "24px",
                        }}
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={props.data ? props.data.twitter : "/"}
                      style={{
                        color: "#000",
                        display: "block",
                        fontSize: "24px",
                        margin: "0 10px",
                      }}
                    >
                      <i
                        className="fa fa-twitter"
                        style={{
                          color: "#000",
                          width: "24px",
                          height: "24px",
                        }}
                      ></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={props.data ? props.data.youtube : "/"}
                      style={{
                        color: "#000",
                        display: "block",
                        fontSize: "24px",
                        margin: "0 10px",
                      }}
                    >
                      <i
                        className="fa fa-youtube"
                        style={{
                          color: "#000",
                          width: "24px",
                          height: "24px",
                        }}
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="text-center" style={{ marginTop: "20px" }}>
                <button
                  onClick={openTermsPopup}
                  style={{
                    background: "none",
                    border: "none",
                    color: "#000",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontSize: "14px",
                  }}
                >
                  Terms and Conditions / Privacy Policy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showTerms && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            padding: "15px",
          }}
          onClick={() => setShowTerms(false)}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              width: window.innerWidth < 768 ? "90%" : "800px",
              height: window.innerWidth < 768 ? "80vh" : "80vh",
              overflow: "auto",
              position: "relative",
              color: "#000",
              fontSize: window.innerWidth < 768 ? "14px" : "16px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={() => setShowTerms(false)}
              style={{
                position: "sticky",
                top: "0",
                float: "right",
                background: "#f0f0f0",
                border: "none",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                fontSize: "20px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
                zIndex: 1001,
              }}
            >
              ×
            </button>

            <div
              style={{
                padding: "10px",
                clear: "both",
              }}
            >
              <h2
                style={{
                  fontSize: window.innerWidth < 768 ? "20px" : "24px",
                  marginBottom: "20px",
                }}
              >
                Terms and Conditions
              </h2>

              <div
                style={{
                  lineHeight: "1.6",
                  "& h4": { marginTop: "24px", marginBottom: "16px" },
                  "& h5": { marginTop: "20px", marginBottom: "12px" },
                  "& p": { marginBottom: "16px" },
                  "& ul": {
                    marginLeft: "20px",
                    marginBottom: "16px",
                    paddingLeft: "15px",
                  },
                }}
              >
                <h3>Privacy Policy and Terms of Service</h3>
                <p>
                  <strong>Effective Date: November 1 2024</strong>
                </p>
                <p>
                  Welcome to westcliff.io ("we," "our," or "us"). By using our
                  website or services, you agree to the following terms of
                  service and privacy policy. Please read this document
                  carefully before using our services.
                </p>

                <h4>1. Terms of Service</h4>
                <h5>1.1 Use of Our Services</h5>
                <p>
                  By accessing or using our website, you agree to comply with
                  these terms and applicable laws. You may not use our services
                  for any unlawful or prohibited purpose, including but not
                  limited to:
                </p>
                <ul>
                  <li>Sending spam or unauthorized communications.</li>
                  <li>Harming our website, network, or other users.</li>
                </ul>

                <h5>1.2 User Content</h5>
                <p>
                  You retain ownership of any content you post or submit to our
                  website. However, by submitting content, you grant us a
                  non-exclusive, royalty-free, worldwide license to use,
                  reproduce, and display your content as necessary to provide
                  the service.
                </p>

                <h5>1.3 Account Security</h5>
                <p>
                  You are responsible for maintaining the confidentiality of
                  your account information. Notify us immediately if you suspect
                  unauthorized use of your account.
                </p>

                <h5>1.4 Service Availability</h5>
                <p>
                  We strive to keep our services available 24/7 but do not
                  guarantee uninterrupted access. We reserve the right to
                  modify, suspend, or discontinue any part of our website
                  without notice.
                </p>

                <h5>1.5 Limitation of Liability</h5>
                <p>
                  We are not liable for any damages, including direct, indirect,
                  or consequential losses, arising from your use of our website
                  or services.
                </p>

                <h4>2. Privacy Policy</h4>
                <h5>2.1 Information We Collect</h5>
                <p>We collect the following types of information:</p>
                <ul>
                  <li>
                    Personal Information: Name, email address, phone number, and
                    other contact details provided when you sign up or contact
                    us.
                  </li>
                  <li>
                    Usage Data: Information about your interactions with our
                    website, such as IP address, browser type, and pages viewed.
                  </li>
                  <li>
                    Cookies and Tracking: We use cookies to enhance user
                    experience, track website usage, and improve our services.
                  </li>
                </ul>

                <h5>2.2 How We Use Your Information</h5>
                <p>We use your information to:</p>
                <ul>
                  <li>Provide and improve our services.</li>
                  <li>
                    Communicate with you about updates, offers, and inquiries.
                  </li>
                  <li>Ensure the security and functionality of our website.</li>
                </ul>

                <h5>2.3 Sharing of Information</h5>
                <p>
                  We do not sell your personal information. We may share your
                  information with:
                </p>
                <ul>
                  <li>
                    Service Providers: Trusted third parties who assist in
                    operating our website.
                  </li>
                  <li>
                    Legal Authorities: If required to comply with legal
                    obligations.
                  </li>
                </ul>

                <h5>2.4 Data Security</h5>
                <p>
                  We implement appropriate security measures to protect your
                  data. However, no system can guarantee absolute security.
                </p>

                <h5>2.5 Your Rights</h5>
                <p>You have the right to:</p>
                <ul>
                  <li>Access, update, or delete your personal information.</li>
                  <li>Opt out of marketing communications.</li>
                </ul>
                <p>
                  To exercise these rights, please contact us at
                  webadmin@westcliff.io
                </p>

                <h4>3. Changes to this Policy</h4>
                <p>
                  We may update this Privacy Policy and Terms of Service from
                  time to time. Changes will be posted on this page with an
                  updated effective date. Continued use of our website after
                  changes indicates acceptance of the revised policy.
                </p>

                <h4>4. Contact Us</h4>
                <p>
                  If you have questions about this Privacy Policy and Terms of
                  Service, please contact us at: Email: webadmin@westcliff.io
                </p>
                <p>
                  By using our website, you confirm that you have read,
                  understood, and agreed to this Privacy Policy and Terms of
                  Service.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        id="footer"
        style={{
          background: "#fff",
          color: "#000",
          padding: "1.5rem 0",
          borderTop: "1px solid #000",
        }}
      >
        <div className="container text-center">
          <p
            style={{
              margin: "0",
              fontSize: "1.5rem",
              color: "#000",
              fontWeight: "500",
            }}
          >
            Copyright &copy; Westcliff Technologies 2024-2025
          </p>
        </div>
      </div>
    </div>
  );
};
